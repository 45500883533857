// RoadConditionsMap.jsx

import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  CircleMarker,
  Popup,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Import utilities
import { convertRoadSegmentsToGeoJSON, convertIncidentsToGeoJSON } from "./utils";

// Import styles
import {
  segmentInnerStyle,
  segmentLeftOutlineStyle,
  segmentRightOutlineStyle,
  routeSegmentMarkerStyle,
  incidentStyle,
} from "./styles";

// Import components
import ToggleControls from "./components/ToggleControls";
import RoadSegmentPopup from "./components/RoadSegmentPopup";

// Main map component
const RoadConditionsMap = ({ routes, mapConfig }) => {
  const [isMobile, setIsMobile] = useState(false);

  const { center, zoom, minZoom, maxZoom } = mapConfig || {
    center: [39.5, -105.85],
    zoom: 9,
    minZoom: undefined, //isMobile ? undefined : 7,
    maxZoom: 10,
  };

  useEffect(() => {
    // Detect screen size to determine if it's a mobile device
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const initialRouteToggles = Object.keys(routes).reduce((acc, routeName) => {
    acc[routeName] = true; // All routes visible by default
    return acc;
  }, {});

  const [routeVisibility, setRouteVisibility] = useState(initialRouteToggles);
  const [showSegments, setShowSegments] = useState(true);
  const [showIncidents, setShowIncidents] = useState(true);

  const toggleRouteVisibility = (routeName) => {
    setRouteVisibility((prevVisibility) => ({
      ...prevVisibility,
      [routeName]: !prevVisibility[routeName],
    }));
  };

  const incidentGeoJSON = convertIncidentsToGeoJSON(routes);

  // Popup logic for GeoJSON road segments
  const onEachFeature = (feature, layer) => {
    const { name, routeName, primaryMP, secondaryMP, currentConditions } =
      feature.properties;

    let conditionsHtml = "None";
    if (Array.isArray(currentConditions) && currentConditions.length > 0) {
      conditionsHtml = currentConditions
        .slice()
        .sort((a, b) => {
          if (a.forecastAvailable === null && b.forecastAvailable === null) return 0;
          if (a.forecastAvailable === null) return -1;
          if (b.forecastAvailable === null) return 1;
          return 0;
        })
        .map((condition) => {
          const startTime = condition.startTime ? new Date(condition.startTime) : null;
          const endTime = condition.endTime ? new Date(condition.endTime) : null;
          const endTimeSub50 = endTime ? endTime.setFullYear(endTime.getFullYear() - 50) : null;
          return `
          <div>
            <strong>${condition.forecastAvailable || "Condition"}:</strong> ${
          condition.conditionDescription || "N/A"
        }<br>
            Start: ${startTime ? startTime.toLocaleString() : "N/A"}<br>
            ${
              endTimeSub50 && startTime && endTimeSub50 < startTime
                ? `End: ${new Date(condition.endTime).toLocaleString()}<br>`
                : ""
            }
          </div>
        `;
        })
        .join("<br>");
    }

    layer.bindPopup(` 
      <strong>${name || "Unnamed Segment"}</strong><br>
      Route: ${routeName || "Unknown"}<br>
      Mileposts: ${primaryMP} - ${secondaryMP}<br><br>
      ${conditionsHtml} 
    `);
  };

  const createCustomIcon = (feature) => {
    const severityIcon = getSeverityIcon(feature.properties.severity); // Function to determine the icon based on severity
    return L.divIcon({
      className: `incident-icon ${feature.properties.severity}`,
      html: `<span class="material-icons severity-icon">${severityIcon}</span>`,
      iconSize: [24, 24], // Adjust size as needed
    });
  };
  
  // Helper function to determine the icon name based on severity
  const getSeverityIcon = (severity) => {
    switch (severity) {
      case "minor":
        return "warning"; // Replace with appropriate material icon
      case "severe":
        return "report_problem";
      case "major":
        return "error";
      default:
        return "help";
    }
  }; 

  return (
    <MapContainer
      id="map"
      center={center}
      zoom={zoom}
      minZoom={minZoom}
      maxZoom={maxZoom}
      className="map-container"
      zoomControl={false}
    >
      <TileLayer
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Render GeoJSON road segments */}
      {Object.entries(routes).map(([routeName, route]) => {
        if (!routeVisibility[routeName]) return null;

        const geoJsonData = convertRoadSegmentsToGeoJSON(route.roadSegments);
        if (!geoJsonData) return null;

        return (
          <React.Fragment key={routeName}>
            {/* Left outline */}
            <GeoJSON
              data={geoJsonData}
              style={segmentLeftOutlineStyle}
              onEachFeature={onEachFeature}
            />

            {/* Right outline */}
            <GeoJSON
              data={geoJsonData}
              style={segmentRightOutlineStyle}
              onEachFeature={onEachFeature}
            />

            {/* Inner line */}
            <GeoJSON
              data={geoJsonData}
              style={segmentInnerStyle}
              onEachFeature={onEachFeature}
            />

            {/* Start and End Markers */}
            {
              route.roadSegments.map((segment, index) => (
                <React.Fragment key={`${routeName}-marker-${index}`}>
                  { showSegments && <CircleMarker
                    center={[
                      segment.location.primary.latitude,
                      segment.location.primary.longitude,
                    ]}
                    pathOptions={routeSegmentMarkerStyle(showSegments)}
                  >
                    <RoadSegmentPopup segment={segment} type="primaryMP" />
                  </CircleMarker> }
                  { showSegments && <CircleMarker
                    center={[
                      segment.location.secondary.latitude,
                      segment.location.secondary.longitude,
                    ]}
                    pathOptions={routeSegmentMarkerStyle(showSegments)}
                  >
                    <RoadSegmentPopup segment={segment} type="secondaryMP" />
                  </CircleMarker>  }
                </React.Fragment>
              ))}
          </React.Fragment>
        );
      })}

      {/* Render incidents */}
{showIncidents && (
  <GeoJSON
    data={incidentGeoJSON}
    pointToLayer={(feature, latlng) => {
      // Use the custom icon instead of a circle marker
      return L.marker(latlng, { icon: createCustomIcon(feature) });
    }}
  >
    {incidentGeoJSON.features.map((feature, index) => (
      <Popup
        key={`incident-popup-${index}`}
        position={feature.geometry.coordinates.reverse()}
      >
        {/* Popup content for incident */}
        <strong>Incident</strong>
        <br />
        Severity: {feature.properties.severity || "Unknown"}
        <br />
        Status: {feature.properties.status || "Unknown"}
        <br />
        Message:{" "}
        {feature.properties.travelerInformationMessage ||
          "No details available"}
        <br />
        Last Updated:{" "}
        {new Date(feature.properties.lastUpdated).toLocaleString() || "N/A"}
        <br />
        Route Name: {feature.properties.routeName}
      </Popup>
    ))}
  </GeoJSON>
)}

      {/* Toggle controls */}
      <ToggleControls
        routes={routes}
        routeVisibility={routeVisibility}
        toggleRouteVisibility={toggleRouteVisibility}
        showSegments={showSegments}
        setShowSegments={setShowSegments}
        showIncidents={showIncidents}
        setShowIncidents={setShowIncidents}
      />
    </MapContainer>
  );
};

export default RoadConditionsMap;


// import React, { useState } from "react";
// import { MapContainer, TileLayer, GeoJSON, CircleMarker, Popup, useMap } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// //
// // === UTILITY FUNCTIONS ===
// //

// // Convert road segments to GeoJSON
// /* conditon description key 
// A - Dry (3)
// B - Scattered showers (21)
// C - Wet (4)
// D - Wet in areas (4s)
// E - Poor visibility (2)
// F - Rain (5)
// G - Slushy (7)
// H - Snow (6)
// I - Icy spots (9)
// J - Snow packed / Icy (10, 8)
// 11 snow packed spots
// */
// const convertRoadSegmentsToGeoJSON = (roadSegments) => {
//   if (!Array.isArray(roadSegments) || roadSegments.length === 0) {
//     return null;
//   }

//   return {
//     type: "FeatureCollection",
//     features: roadSegments.map((segment) => ({
//       type: "Feature",
//       geometry: {
//         type: segment.geometry.type,
//         coordinates: segment.geometry.coordinates,
//       },
//       properties: {
//         id: segment.id,
//         name: segment.name,
//         routeId: segment.route.routeId,
//         routeName: segment.route.routeName,
//         primaryMP: Math.floor(segment.milepost.primaryMP * 10) / 10,
//         secondaryMP: Math.floor(segment.milepost.secondaryMP * 10) / 10,
//         primaryLatitude: segment.location.primary.latitude,
//         primaryLongitude: segment.location.primary.longitude,
//         secondaryLatitude: segment.location.secondary.latitude,
//         secondaryLongitude: segment.location.secondary.longitude,
//         currentConditionsSummary: segment.currentConditionsSummary,
//         currentConditions: segment.currentConditions,
//         forecastAvailable: segment.forecastAvailable,
//       },
//     })),
//   };
// };

// // Convert incidents to GeoJSON
// const convertIncidentsToGeoJSON = (routes) => {
//   const features = [];

//   Object.values(routes).forEach((route) => {
//     Object.values(route.subroutes).forEach((subroute) => {
//       subroute.incidents.forEach((incident) => {
//         features.push({
//           type: "Feature",
//           geometry: incident.geometry,
//           properties: {
//             id: incident.properties.id,
//             severity: incident.properties.severity,
//             status: incident.properties.status,
//             startMarker: incident.properties.startMarker,
//             endMarker: incident.properties.endMarker,
//             type: incident.properties.type,
//             travelerInformationMessage: incident.properties.travelerInformationMessage,
//             lastUpdated: incident.properties.lastUpdated,
//             routeName: incident.properties.routeName,
//           },
//         });
//       });
//     });
//   });

//   return {
//     type: "FeatureCollection",
//     features,
//   };
// };
 
// // GeoJSON layer style
// const getOverlayColor = (feature) => {
//   const routeName = feature.properties.routeName || "default";
//   let color = routeColors[routeName] || defaultColor;

//   const currentConditions = feature.properties.currentConditions || [];
//   let shouldBeRed = false;
//   let shouldBeYellow = false;

//   for (const condition of currentConditions) {
//     if (condition.forecastAvailable === null) {
//       const description = condition.conditionDescription || "";
//       const firstPart = description.substring(0, 9);
//       const numbers = firstPart.match(/\d+/g);
//       if (numbers) {
//         const parsedNumbers = numbers.map(Number);

//         if (parsedNumbers.some((num) => num > 6 && num !== 21)) {
//           shouldBeRed = true;
//           break;
//         }

//         if (parsedNumbers.some((num) => [5, 4, 21].includes(num))) {
//           shouldBeYellow = true;
//         }
//       }
//     }
//   }

//   if (shouldBeRed) {
//     color = "red";
//   } else if (shouldBeYellow) {
//     color = "yellow";
//   }

//   return color;
// };

// const segmentOverlayStyle = (feature) => {
//   const overlayColor = getOverlayColor(feature);

//   if (overlayColor) {
//     let dashArray;
//     if (overlayColor === 'red') {
//       dashArray = '10,5'; // Dash pattern for red overlay
//     } else if (overlayColor === 'yellow') {
//       dashArray = '5,10'; // Dash pattern for yellow overlay
//     }

//     return {
//       color: overlayColor,
//       weight: 4,
//       opacity: 1,
//      //dashArray: dashArray,
//       //dashOffset: 20,
//     };
//   }
// }
// //
// // === STYLING ===
// //
// const segmentInnerStyle = (feature) => segmentOverlayStyle(feature);

// // Style for the inner line
// const segmentInnerStyleOLD = (feature) => ({
//   color: routeColors[feature.properties.routeName] || defaultColor,
//   weight: 4,
//   opacity: 0.7,
//   //dashArray: '5, 10', dashOffset: '20'
// });

// const segmentLeftOutlineStyle = (feature) => ({
//   color: "#0a11d8", // Left outline color
//   weight: 9, // Wider than the inner line
//   opacity: 0.7,
// });

// const segmentRightOutlineStyle = (feature) => ({
//   color: "#0a11d8", // Right outline color
//   weight: 7, // Slightly narrower than the left outline
//   opacity: 0.7,
// }); 

// // Predefined colors for specific route names
// const routeColors = {
//   "I-70": "#0f53ff", 
//   "US-6": "#0f53ff", 
//   "US-40": "#0f53ff", 
//   "CO-9": "#0f53ff",  
// };
// //

// // Default fallback color
// const defaultColor = "#808080";

// // Circle marker style for segments 
// const routeSegmentMarkerStyle = (showSegments) => {
//   return {
//     color: showSegments ? "#000" : "#fff",
//     fillColor: showSegments ? "#fff" : "#000",
//     fillOpacity: showSegments ? 0.8 : 0.6,
//     opacity: showSegments ? 0.6 : 0.4,
//     radius: showSegments ? 5 : 3,
//   };
// };


// // Dynamic styling for incidents
// const incidentStyle = (feature) => {
//   const severity = feature.properties.severity?.toLowerCase();
//   let fillColor;

//   switch (severity) {
//     case "minor":
//       fillColor = "yellow";
//       break;
//     case "major":
//       fillColor = "orange";
//       break;
//     case "severe":
//       fillColor = "red";
//       break;
//     default:
//       fillColor = "gray";
//       break;
//   }

//   return {
//     color: fillColor,
//     radius: 8,
//     fillColor: fillColor,
//     fillOpacity: 0.7,
//   };
// };

// //
// // === COMPONENTS ===
// //
// const ToggleControls = ({
//   routes,
//   routeVisibility,
//   toggleRouteVisibility,
//   showSegments,
//   setShowSegments,
//   showIncidents,
//   setShowIncidents,
// }) => {
//   const map = useMap(); // Access the map instance

//   const controlRef = React.useRef();

//   // Prevent interaction events from propagating to the map
//   React.useEffect(() => {
//     if (controlRef.current) {
//       const controlElement = controlRef.current;

//       L.DomEvent.disableClickPropagation(controlElement);
//       L.DomEvent.disableScrollPropagation(controlElement);
//     }
//   }, []);

//   return (
//     <div
//       ref={controlRef}
//       onMouseEnter={() => map.dragging.disable()}
//       onMouseLeave={() => map.dragging.enable()}
//       style={{
//         position: "absolute",
//         bottom: "20px",
//         right: "0px",
//         background: "white",
//         padding: "10px",
//         borderRadius: "5px",
//         zIndex: 1000,
//       }}
//     >
//       <h4 style={{ margin: "5px 0 5px 0" }}>Toggle Routes</h4>
//       <div
//         style={{
//           display: "grid",
//           gridTemplateColumns: "repeat(2, 1fr)",
//           gap: "3px",
//         }}
//       >
//         {Object.keys(routes).map((routeName) => (
//           <label key={routeName} style={{ display: "flex", alignItems: "center" }}>
//             <input
//               type="checkbox"
//               checked={routeVisibility[routeName]}
//               onChange={() => toggleRouteVisibility(routeName)}
//             />
//             {routeName}
//           </label>
//         ))}
//       </div>
//       <hr />
//       <label>
//         <input
//           type="checkbox"
//           checked={showSegments}
//           onChange={() => setShowSegments(!showSegments)}
//         />
//         Start/End Markers
//       </label>
//       <br />
//       <label>
//         <input
//           type="checkbox"
//           checked={showIncidents}
//           onChange={() => setShowIncidents(!showIncidents)}
//         />
//          {showIncidents ? 'Hide Incidents' : 'Show Incidents'}
//       </label>
//     </div>
//   );
// };

// // Popup for road segments
// const RoadSegmentPopup = ({ segment, type }) => {
//   const { name, route, milepost, currentConditions } = segment;

//   const formatTimestamp = (timestamp) =>
//     timestamp ? new Date(timestamp).toLocaleString() : "N/A";

//   return (
//     <Popup>
//       <strong>{name || "Unnamed"}</strong>
//       <br />
//       Route: {route.routeName || "Unknown"} ({route.routeId || "N/A"})
//       <br />
//       Milepost: {milepost[type]} ({type === "primaryMP" ? "Start" : "End"})
//       <br />
//       {/* <br />
//       <strong>Conditions:</strong> */}
//       <br />
//       {Array.isArray(currentConditions) && currentConditions.length > 0 ? (
//         currentConditions
//           .slice() // Create a shallow copy to avoid mutating the original array
//           .sort((a, b) => {
//             if (a.forecastAvailable === null && b.forecastAvailable === null) return 0; // Both null
//             if (a.forecastAvailable === null) return -1; // a is null, push it to the end
//             if (b.forecastAvailable === null) return 1; // b is null, push it to the end
//             return 0;
//           })
//           .map((condition, index) => {
//             // Calculate 50 years ago from the endTime
//             const endTime = condition.endTime ? new Date(condition.endTime) : null;
//             const startTime = condition.startTime ? new Date(condition.startTime) : null;
//             const fiftyYearsAgo = endTime ? new Date(endTime.setFullYear(endTime.getFullYear() - 50)) : null;

//             return (
//               <div key={index}>
//                 {index !== 0 && <br />}
//                 <strong>{condition.forecastAvailable || "Condition"}:</strong>{" "}
//                 {condition.conditionDescription || "N/A"}
//                 <br />
//                 Start Time: {formatTimestamp(condition.startTime)}
//                 {fiftyYearsAgo && startTime && fiftyYearsAgo < startTime && (
//                   <>
//                     <br />
//                     End Time: {formatTimestamp(condition.endTime)}
//                   </>
//                 )}
//                 <br />
//               </div>
//             );
//           })
//       ) : (
//         "None"
//       )}
//     </Popup>
//   );
// };

// //
// // === MAIN MAP COMPONENT ===
// //

// const RoadConditionsMap = ({ routes, mapConfig }) => {
//   const { center, zoom, minZoom, maxZoom } = mapConfig || {
//     center: [39.5, -105.85],
//     zoom: 9,
//     minZoom: 7,
//     maxZoom: 10,
//   };

//   const initialRouteToggles = Object.keys(routes).reduce((acc, routeName) => {
//     acc[routeName] = true; // All routes visible by default
//     return acc;
//   }, {});

//   const [routeVisibility, setRouteVisibility] = useState(initialRouteToggles);
//   const [showSegments, setShowSegments] = useState(true);
//   const [showIncidents, setShowIncidents] = useState(true);

//   const toggleRouteVisibility = (routeName) => {
//     setRouteVisibility((prevVisibility) => ({
//       ...prevVisibility,
//       [routeName]: !prevVisibility[routeName],
//     }));
//   };

//   const incidentGeoJSON = convertIncidentsToGeoJSON(routes);

//   // Popup logic for GeoJSON road segments
//   const onEachFeature = (feature, layer) => {
//     const { name, routeName, primaryMP, secondaryMP, currentConditions } =
//       feature.properties;

//     let conditionsHtml = "None";
//     if (Array.isArray(currentConditions) && currentConditions.length > 0) {
//       conditionsHtml = currentConditions
//         .slice()
//         .sort((a, b) => {
//           if (a.forecastAvailable === null && b.forecastAvailable === null) return 0;
//           if (a.forecastAvailable === null) return -1;
//           if (b.forecastAvailable === null) return 1;
//           return 0;
//         })
//         .map((condition) => {
//           const startTime = condition.startTime ? new Date(condition.startTime) : null;
//           const endTime = condition.endTime ? new Date(condition.endTime) : null;
//           const endTimeSub50 = endTime ? endTime.setFullYear(endTime.getFullYear() - 50) : null;
//           return `
//             <div>
//               <strong>${condition.forecastAvailable || "Condition"}:</strong> ${
//             condition.conditionDescription || "N/A"
//           }<br>
//               Start: ${startTime ? startTime.toLocaleString() : "N/A"}<br>
//               ${
//                 endTimeSub50 && startTime && endTimeSub50 < startTime
//                   ? `End: ${new Date(condition.endTime).toLocaleString()}<br>`
//                   : ""
//               }
//             </div>
//           `;
//         })
//         .join("<br>");
//     }

//     layer.bindPopup(
//       `<strong>${name || "Unnamed Segment"}</strong><br>
//       Route: ${routeName || "Unknown"}<br>
//       Mileposts: ${primaryMP} - ${secondaryMP}<br><br>
//       ${conditionsHtml}`
//       //<strong>Conditions:</strong><br>
//     );
//   };

//   // Popup logic for incidents
//   const onEachIncidentFeature = (feature, layer) => {
//     const {
//       severity,
//       routeName,
//       status,
//       travelerInformationMessage,
//       lastUpdated,
//     } = feature.properties;

//     layer.bindPopup(
//       `<strong>Incident</strong><br>
//       Severity: ${severity || "Unknown"}<br>
//       Status: ${status || "Unknown"}<br>
//       Message: ${travelerInformationMessage || "No details available"}<br>
//       Last Updated: ${new Date(lastUpdated).toLocaleString() || "N/A"} <br>
//       Route Name: ${routeName}`
//     );
//   };

//   return (
//     <MapContainer
//       id="map"
//       center={center}
//       zoom={zoom}
//       minZoom={minZoom}
//       maxZoom={maxZoom}
//       className="map-container"
//       zoomControl={false}
//     >
//       <TileLayer
//         url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />

//       {/* Render GeoJSON road segments */}
//       {Object.entries(routes).map(([routeName, route]) => {
//         if (!routeVisibility[routeName]) return null;

//         const geoJsonData = convertRoadSegmentsToGeoJSON(route.roadSegments); 
//         if (!geoJsonData) return null;

//         return (
//           <React.Fragment key={routeName}>
//             {/* Left outline */}
//             <GeoJSON
//               data={geoJsonData}
//               style={segmentLeftOutlineStyle}
//               onEachFeature={onEachFeature}
//             />

//             {/* Right outline */}
//             <GeoJSON
//               data={geoJsonData}
//               style={segmentRightOutlineStyle}
//               onEachFeature={onEachFeature}
//             />

//             {/* Inner line */}
//             <GeoJSON
//               data={geoJsonData}
//               style={segmentInnerStyle}
//               onEachFeature={onEachFeature}
//             />
//             {
//               route.roadSegments.map((segment, index) => (
//                 <React.Fragment key={`${routeName}-marker-${index}`}>
//                   <CircleMarker
//                     center={[
//                       segment.location.primary.latitude,
//                       segment.location.primary.longitude,
//                     ]}
//                     pathOptions={routeSegmentMarkerStyle(showSegments)}
//                   >
//                     <RoadSegmentPopup segment={segment} type="primaryMP" />
//                   </CircleMarker>
//                   <CircleMarker
//                     center={[
//                       segment.location.secondary.latitude,
//                       segment.location.secondary.longitude,
//                     ]}
//                     pathOptions={routeSegmentMarkerStyle(showSegments)}
//                   >
//                     <RoadSegmentPopup segment={segment} type="secondaryMP" />
//                   </CircleMarker>
//                 </React.Fragment>
//               ))}
//           </React.Fragment>
//         );
//       })}

//       {/* Render incidents */}
//       {showIncidents && (
//         <GeoJSON
//           data={incidentGeoJSON}
//           pointToLayer={(feature, latlng) =>
//             L.circleMarker(latlng, incidentStyle(feature))
//           }
//           onEachFeature={onEachIncidentFeature}
//         />
//       )}

//       {/* Toggle controls */}
//       <ToggleControls
//     routes={routes}
//     routeVisibility={routeVisibility}
//     toggleRouteVisibility={toggleRouteVisibility}
//     showSegments={showSegments}
//     setShowSegments={setShowSegments}
//     showIncidents={showIncidents}
//     setShowIncidents={setShowIncidents}
//   />
//     </MapContainer>
//   );
// };

// export default RoadConditionsMap;
